<template>
  <section id="Rusher">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
import ExperiencesCarousel from "@/components/global/ExperiencesCarousel";

export default {
  name: "Rusher",
  components: {
    ExperiencesCarousel,
  },
  data() {
    return {
      carouselImages: [
        {
          image: "Experiences/rusher-2.jpg",
          title: "Rusher",
          description:
            "The Raider Rusher will add energy to any event by interacting with guests and electrifying the atmosphere.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>